<script>
import LoggerPageUsers from '@/components/LoggerPageUsers.vue';
import FormatUtils from '@/mixins/FormatUtils';
import { mapGetters } from 'vuex';

export default {
  name: 'OEMLoggerEditPage',
  components: {
    LoggerPageUsers,
  },
  mixins: [FormatUtils],
  data() {
    return {
      selected_logger: null,
      agentStatus: {},
      loading: true,
      deletion: {
        hasNoFiles: null,
        hasBeenDeauthed: null,
        serialConfirmation: '',
      },
      deauth: {
        serialConfirmation: '',
      },
      purge: {
        serialConfirmation: '',
        total: null,
        deleted: 0,
      },
      rssh: {
        server: '',
        username: '',
        password: '',
        port: null,
        serverPort: '22',
        submitCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      loggers: 'loggers/data',
      files: 'loggers/files',
    }),
    lid() {
      return this.$route.params.lid;
    },
    logger() {
      return this.loggers[this.lid];
    },
    loggerFiles() {
      if (this.lid in this.files) {
        return this.files[this.lid]
          .filter(f => f.onDisk === true);
      }
      return [];
    },
    fileCount() {
      if (this.lid in this.files) {
        return this.loggerFiles.length;
      }
      return 0;
    },
    agentStatusItems() {
      // Transform the agent status into an array
      return Object.entries(this.agentStatus)
        .reduce((p, [key, value]) => [...p, { key, value }], []);
    },
    deletePrerequisitesMet() {
      return this.deletion.hasNoFiles && this.deletion.hasBeenDeauthed;
    },
    rsshState() {
      if (this.rssh.submitCount === 0) {
        return {
          server: null,
          username: null,
          port: null,
          serverPort: null,
        };
      }

      const server = this.rssh.server.length > 0;
      const username = this.rssh.username.length > 0;
      let port;
      if (this.rssh.port) {
        port = (this.rssh.port > 0 && this.rssh.port <= 65535);
      } else {
        port = false;
      }
      let serverPort;
      if (this.rssh.serverPort) {
        serverPort = (this.rssh.port > 0 && this.rssh.serverPort <= 65535);
      } else {
        serverPort = false;
      }
      return {
        server,
        username,
        port,
        serverPort,
      };
    },
  },
  mounted() {
    this.$Progress.start(); // Show a loading bar
    this.selected_logger = this.$route.params.lid;
    // Get the logger's details
    this.$store.dispatch('loggers/get', this.lid)
      .then(() => {
        this.$route.meta.breadcrumbs[3].text = this.logger.serial;
      })
      .then(() => this.$store.dispatch('loggers/getFiles', this.lid))
      .then(() => this.$http({
        method: 'GET',
        url: `/oem/logger/${this.selected_logger}/agent-status`,
      }))
      .then((resp) => {
        if (resp.status === 200) {
          this.agentStatus = resp.data;
        } else {
          this.agentStatus = {};
        }
      })
      .then(() => {
        this.$Progress.finish();
        this.loading = false;
      })
      .catch(() => {
        this.$bvToast.toast('An error occurred', {
          title: 'Error',
          variant: 'danger',
          toaster: 'bv-toaster-bottom-center',
        });
        setTimeout(() => this.$router.go(-1), 2000);
      });
  },
  methods: {
    formatSerialNumber(input) {
      return input.toUpperCase();
    },
    deauthenticateLogger() {
      if (this.deauth.serialConfirmation === this.logger.serial) {
        this.$http({
          method: 'DELETE',
          url: `/oem/logger/${this.selected_logger}/auth`,
        })
          .then(() => {
            this.$bvToast.toast('Logger successfully deauthenticated', {
              title: 'Success',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
            this.$bvModal.hide('oem-logger-deauth');
          });
      }
    },
    purgeFiles() {
      if (this.purge.serialConfirmation === this.logger.serial) {
        // To purge the files, we have to manually delete each one
        // Setup the UI to show a progress bar
        this.purge.deleted = 0;
        this.purge.total = this.fileCount;

        // Map the logger files into an array of deletion request promises
        const deletePromises = this.loggerFiles.map(file => this.$http({
          method: 'DELETE',
          url: `/logger/${this.selected_logger}/files/${encodeURIComponent(file.filename)}`,
        })
          .then((resp) => {
            // Once complete, increment the delete counter
            if (resp.status === 204) {
              this.purge.deleted += 1;
            }
          })
          // Ignore individual failures
          .catch(() => null));

        // Wait for everything to finish
        Promise.all(deletePromises)
          // Refresh the logger file list in the store
          .then(() => this.$store.dispatch('loggers/getFiles', this.selected_logger))
          .then(() => {
            // Check that there are no more files
            if (this.fileCount < this.purge.total) {
              this.$bvToast.toast(
                `Failed to delete all files. ${this.purge.total - this.fileCount} files were deleted`, {
                  title: 'Partial Success',
                  variant: 'warning',
                  toaster: 'b-toaster-bottom-center',
                },
              );
            } else {
              // Print out a message and hide the dialog
              this.$bvToast.toast(`${this.purge.total} files successfully deleted`, {
                title: 'Success',
                variant: 'success',
                toaster: 'b-toaster-bottom-center',
              });
            }
            this.purge.total = null;
            this.purge.deleted = 0;
          })
          .finally(() => this.$bvModal.hide('oem-logger-purge'));
      }
    },
    deleteModalClosed() {
      // When the delete modal is closed, clear the serial number input field
      this.deletion.serialConfirmation = '';
    },
    checkDeletePrerequisites() {
      this.$http({
        method: 'GET',
        url: `/oem/logger/${this.selected_logger}/auth`,
      })
        .then((resp) => {
          this.deletion.hasNoFiles = this.fileCount === 0;
          this.deletion.hasBeenDeauthed = !resp.data.hasAuth;
        });
    },
    deleteLogger() {
      if (this.deletePrerequisitesMet && this.deletion.serialConfirmation === this.logger.serial) {
        this.$http({
          method: 'DELETE',
          url: `/oem/logger/${this.lid}`,
        })
          .then((resp) => {
            if (resp.status === 204) {
              this.$bvToast.toast('Logger successfully deleted. Please wait...', {
                title: 'Success',
                variant: 'success',
                toaster: 'b-toaster-bottom-center',
                noAutoHide: true,
                noCloseButton: true,
              });
              setTimeout(() => this.$router.replace('/oem/logger'), 2000);
            } else {
              throw new Error('DeletionFailed');
            }
          })
          .catch(() => {
            this.$bvToast.toast('Failed to delete logger', {
              title: 'Failed',
              variant: 'danger',
              toaster: 'b-toaster-bottom-center',
            });
          });
      }
    },
    rebootCommand() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to send a reboot command to this logger?')
        .then((conf) => {
          if (conf) {
            this.$store.dispatch('loggers/issueCommand', {
              lid: this.lid,
              command: 'reboot',
              params: [],
            })
              .then(() => {
                this.$bvToast.toast('Command issued successfully', {
                  title: 'Success',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              })
              .catch(() => {
                this.$bvToast.toast('Failed to issue reboot. Try again later', {
                  title: 'Failure',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
              });
          }
        });
    },
    reverseSsh(ev) {
      // Check the validations passed
      this.rssh.submitCount += 1;
      if (this.rsshState.server && this.rsshState.username && this.rsshState.port) {
        this.$store.dispatch('loggers/issueCommand', {
          lid: this.lid,
          command: 'rssh',
          params: [
            this.rssh.server,
            this.rssh.username,
            this.rssh.password,
            this.rssh.port,
            this.rssh.serverPort,
          ],
        })
          .then(() => {
            this.$bvToast.toast('Command issued successfully', {
              title: 'Success',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
            this.clearReverseSshForm();
          })
          .catch(() => {
            this.$bvToast.toast('Failed to issue reverse SSH command. Try again later', {
              title: 'Failure',
              variant: 'danger',
              toaster: 'b-toaster-bottom-center',
            });
            ev.preventDefault();
          });
      } else {
        ev.preventDefault();
      }
    },
    clearReverseSshForm() {
      this.rssh.server = '';
      this.rssh.username = '';
      this.rssh.password = '';
      this.rssh.port = null;
      this.rssh.submitCount = 0;
    },
  },
};
</script>

<template>
  <b-container>

    <!-- Begin main page content -->
    <b-row v-if='!loading'>
      <b-col>
        <b-card :title='this.logger.serial' class='mb-4'>

          <!-- Begin display of static info -->
          <b-row align-v='end' class='mb-5'>
            <b-col cols=6>
              <span class='text-muted'>
                Last seen: {{ $d(logger.last_seen, 'long') }}
              </span>
            </b-col>

            <b-col />

            <b-col cols='5' md='3' xl='2' align-self='end'>
              <b-dropdown right text='Admin Actions' variant='success'>
                <b-dropdown-item-btn v-b-modal.logger-rssh-start>
                  Open Reverse SSH Connection
                </b-dropdown-item-btn>
                <b-dropdown-item-btn @click="rebootCommand()">
                  Reboot Comms
                </b-dropdown-item-btn>
              </b-dropdown>
            </b-col>
          </b-row>

          <!-- Display some random stats -->
          <b-row>
            <b-col md='6'>
              File count: {{ fileCount }}<br>
              Total Quota:
              {{ logger.quota.total ? formatFileSize(logger.quota.total) : 'Unlimited' }}<br>
              Used Quota: {{ formatFileSize(logger.quota.used) }}<br>
            </b-col>

            <!-- Display logger status -->
            <b-col md='6'>
              <b-table-simple>
                <b-tbody>
                  <b-tr>
                    <b-td>Status</b-td>
                    <b-td v-if='logger.status'>{{ formatStatus(logger.status) }}</b-td>
                    <b-td v-else>unavailable</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Battery Voltage</b-td>
                    <b-td v-if='logger.status'>{{ logger.batteryVolts }} V</b-td>
                    <b-td v-else>unavailable</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Ambient Temperature</b-td>
                    <b-td v-if='logger.status'>{{ logger.ambientTemp }} &deg;C</b-td>
                    <b-td v-else>unavailable</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Firmware</b-td>
                    <b-td v-if='logger.status'>{{ logger.firmware }}</b-td>
                    <b-td v-else>unavailable</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Free Memory</b-td>
                    <b-td v-if='logger.status'>{{ logger.freeMemory }} %</b-td>
                    <b-td v-else>unavailable</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>

          </b-row>
          <!-- End display of static info -->

          <!-- Begin Go-to button -->
          <b-row align-h='center' class='mb-3'>
            <b-col sm='6'>
              <b-btn variant='secondary' :to='`/logger/${this.selected_logger}`' block>
                Go to logger page <fa-icon icon='arrow-right'/>
              </b-btn>
            </b-col>
          </b-row>
          <!-- End Go-to button -->

          <!-- Begin Owner/user dialog -->
          <b-row class='mb-4'>
            <b-col>
              <logger-page-users :lid='this.selected_logger' title='Logger Ownership' admin/>
            </b-col>
          </b-row>
          <!-- End Owner/user dialog -->

          <!-- Begin Agent Status Table -->
          <b-row class='mb-4'>
            <b-col>
              <p class='lead'>Agent Status</p>
              <b-table outlined
                       :items='agentStatusItems'
                       empty-text="No agent status available"
                       show-empty />
            </b-col>
          </b-row>
          <!-- End Agent Status Table -->

          <!-- Begin Danger Controls -->
          <b-row>
            <b-col cols='12'>
              <b-card header='Danger Area'
                      border-variant='danger'
                      header-border-variant='danger'
                      header-text-variant='danger'
                      align='center'
                      class='mt-4 mb-4'>

                <b-row align-h='center' class='mb-2'>

                  <b-col cols='12' md='6' order='1' class='mt-2'>
                    Remove current login information from database.
                    This will require the logger to be re-authenticated in PMScreen.
                  </b-col>

                  <b-col cols='12' md='6' order='2' order-md='3'>
                    <b-btn v-b-modal.oem-logger-deauth variant='outline-danger' block>
                      Deauthenticate Logger
                    </b-btn>
                  </b-col>

                  <b-col cols='12' md='6' order='3' order-md='2' class='mt-2'>
                    Delete all files that are stored in pmgateway.online from the logger.
                  </b-col>

                  <b-col cols='12' md='6' order='4'>
                    <b-btn v-b-modal.oem-logger-purge variant='outline-danger' block>
                      Delete All Files
                    </b-btn>
                  </b-col>

                </b-row>

                <b-row align-h='center' class='mt-5'>

                  <b-col cols='12'>
                    Delete any data associated with this logger from the system.
                  </b-col>

                  <b-col cols='12' class='mt-2'>
                    <b-btn v-b-modal.oem-logger-delete variant='danger' block>
                      Delete Logger From System
                    </b-btn>
                  </b-col>

                </b-row>

              </b-card>
            </b-col>
          </b-row>
          <!-- End Danger Controls -->

        </b-card>
      </b-col>
    </b-row>
    <!-- End main page content -->

    <!-- Begin logger delete modal -->
    <b-modal id='oem-logger-delete'
             size='lg'
             title='Delete Logger'
             @show='checkDeletePrerequisites'
             @hidden='deleteModalClosed'>
      <p>
        Are you sure you want to delete this logger? This action is irreversible!
      </p>

      <p>
        Prerequisites:
        <ul>
          <li v-bind:class="deletion.hasNoFiles ? 'text-success' : 'text-danger'">
            Logger has no files stored on system
            <fa-icon v-if='deletion.hasNoFiles == true' icon='check' class='ml-1'/>
            <fa-icon v-else-if='deletion.hasNoFiles === false' icon='times' class='ml-1'/>
            <b-spinner v-else small variant='primary' />
          </li>
          <li v-bind:class="deletion.hasBeenDeauthed ? 'text-success' : 'text-danger'">
            Logger has been deauthenticated
            <fa-icon v-if='deletion.hasBeenDeauthed == true' icon='check' class='ml-1'/>
            <fa-icon v-else-if='deletion.hasBeenDeauthed === false' icon='times' class='ml-1'/>
            <b-spinner v-else small variant='primary' />
          </li>
        </ul>
      </p>

      <p v-if='!deletePrerequisitesMet' class='text-danger'>
        Please resolve the prerequisites before deleting
      </p>
      <p v-else class='font-weight-bold'>
        Confirm the logger serial number below to continue
      </p>

      <!-- Dialog footer -->
      <template slot='modal-footer'>
        <b-input-group>
          <b-form-input placeholder='Type logger serial number'
                        :formatter="formatSerialNumber"
                        v-model='deletion.serialConfirmation'
                        v-bind:disabled='!deletePrerequisitesMet'/>
          <b-input-group-append>
            <b-btn variant='danger'
                   v-bind:disabled='!logger || (deletion.serialConfirmation !== logger.serial)'
                   @click='deleteLogger'>
              DELETE LOGGER
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </template>

    </b-modal>
    <!-- End logger delete modal -->

    <!-- Begin logger deauth modal -->
    <b-modal id='oem-logger-deauth'
             title='Deauthenticate Logger'
             @hidden="deauth.serialConfirmation = ''">
      <p>
        If you deauthenticate this logger, it will stop communicating with PMGateway.
        The only way to resume communications will be for the owner account to login again using
        PMScreen.
      </p>
      <p>
        All files will remain on PMGateway, and users will still be able to access it.
      </p>
      <p class='font-weight-bold'>
        Confirm the logger serial number below to continue.
      </p>

      <template slot="modal-footer">
        <b-input-group>
          <b-form-input placeholder='Type logger serial number'
                        :formatter="formatSerialNumber"
                        v-model='deauth.serialConfirmation'/>
          <b-input-group-append>
            <b-btn variant='danger'
                   v-bind:disabled='!logger || (deauth.serialConfirmation !== logger.serial)'
                   @click='deauthenticateLogger'>
              DEAUTHENTICATE
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </template>
    </b-modal>
    <!-- End logger deauth modal -->

    <!-- Begin logger file purge modal -->
    <b-modal id='oem-logger-purge'
             title='Delete All Files'
             @hidden="this.purge.serialConfirmation = ''">
      <p>
        This will permenantely delete all files stored for this logger on PMGateway.
      </p>
      <p v-if='fileCount > 0'>
        {{ fileCount }} files will be deleted.
      </p>
      <p v-else class='font-weight-bold'>
        There are no files stored for this logger.
      </p>

      <p v-if='fileCount > 0' class='font-weight-bold'>
        Confirm the logger serial number below to continue.
      </p>

      <template slot="modal-footer">
        <b-input-group>
          <b-form-input placeholder='Type logger serial number'
                        :formatter="formatSerialNumber"
                        v-model='purge.serialConfirmation'
                        v-bind:disabled='fileCount == 0'/>
          <b-input-group-append>
            <b-btn variant='danger'
                   v-bind:disabled='!logger || (purge.serialConfirmation !== logger.serial)'
                   @click='purgeFiles'>
              DELETE ALL FILES
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </template>

    </b-modal>
    <!-- End logger file purge modal -->

    <b-modal id='oem-file-delete-progress'
             hide-header
             body-class='text-center'
             hide-footer
             hide-header-close
             no-close-on-backdrop
             no-close-on-esc
             centered
             v-bind:visible='!!purge.total'>

      <b-spinner variant='primary'/>
      <p class='lead'>
        Deleting {{ purge.total }} files...
      </p>
      <b-progress v-bind:max='purge.total'
                  v-bind:value='purge.deleted'
                  show-value
                  height='2rem'/>
      <p class='text-muted'>
        Closing this window will cancel the operation
      </p>
    </b-modal>

    <!-- Begin Reverse SSH Modal -->
    <b-modal id='logger-rssh-start'
             title='Open Reverse SSH Connection'
             size='lg'
             @ok='reverseSsh'>
      <!-- Explanation text -->
      <p class='text-muted'>
        When creating a reverse SSH connection, the logger will initiate an SSH connection to
        the provided server with a reverse port forwarding rule in place to open a port on the
        SSH server and tunnel it back to port 22 on the logger. An SSH session can then be opened
        on the remote server by pointing an SSH client at the tunnelled port.
      </p>

      <!-- Begin form components for gathering reverse SSH server info -->
      <b-form novalidate>
        <b-form-group label='Server Address'
                      description='SSH Server to open a reverse ssh connection to'>
          <b-form-input placeholder='e.g 192.0.2.1'
                        v-model='rssh.server'
                        v-bind:state='rsshState.server' />
        </b-form-group>
        <b-form-group label='Server Port'
                      description='Port that the SSH server is listening on'>
          <b-form-input v-model='rssh.serverPort'
                        v-bind:state='rsshState.serverPort'
                        type='number'
                        min=1
                        max='65535' />
        </b-form-group>
        <b-form-group label='Username'
                      description='Username for logging into the SSH server'>
          <b-form-input v-model='rssh.username' v-bind:state='rsshState.username' />
          <b-form-invalid-feedback>
            Please enter a username for the SSH server
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label='Password'
                      description='Password for logging into the SSH server'>
          <b-form-input type='password' v-model='rssh.password' />
        </b-form-group>
        <b-form-group label='Reverse Port'
                      description='Port to open on the SSH server that will tunnel to the logger'>
          <b-input type='number'
                  min='1'
                  max='65535'
                  v-model='rssh.port'
                  v-bind:state='rsshState.port' />
        </b-form-group>
      </b-form>

      <p class='font-weight-bold'>
        Note: The username and password provided will be temporarily stored in the PMGateway
        server database. It's recommended to use a restricted account to perform Reverse SSH.
      </p>

    </b-modal>
    <!-- End reverse ssh modal -->

  </b-container>
</template>
